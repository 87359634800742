<template>
    <div>
        <a-row type="flex" :gutter="24">
            <a-col :span="12" class="mb-24">
                <a-button type="primary" @click="$router.push('/admins/create')">New Admin</a-button>
            </a-col>
        </a-row>
        <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{ padding: 0, paddingTop: '16px' }">
            <div class="mx-25">
                <a-row type="flex" :gutter="24">
                    <a-col :span="24" class="text-right">
                        <a-input-search placeholder="input search text" style="max-width: 200px;" v-model="query"
                            @input="debouncedSearch" />
                    </a-col>
                </a-row>
            </div>
            <a-table class="mt-20" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                :columns="columns" :data-source="admins" rowKey="id"
                :pagination="{ pageSize: pageSize, current: currentPage, total: totalCount, onChange: handlePageChange }"
                :loading="isNotLoading">
                <template slot="first_name" slot-scope="first_name">{{ first_name }}</template>
                <template slot="last_name" slot-scope="last_name">{{ last_name }}</template>
                <template slot="email" slot-scope="email">{{ email }}</template>
                <template slot="role" slot-scope="role">
                    <a-tag :color="getRoleColor(role)" class="role-tag">
                        {{ getRoleLabel(role) }}
                    </a-tag>
                </template>
                <template slot="action" slot-scope="action">
                    <a-button type="primary" @click="$router.push(`/admins/edit/${action}`)">
                        <a-icon type="edit" />
                    </a-button>
                </template>
            </a-table>
        </a-card>
    </div>

</template>

<script>
import adminAPI from '../../api/admin';
import debounce from 'lodash/debounce';
// Table columns
const columns = [
    {
        title: 'FIRST_NAME',
        dataIndex: 'first_name',
        sorter: (a, b) => a.first_name > b.first_name ? 1 : -1,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'first_name' },
    },
    {
        title: 'LAST_NAME',
        dataIndex: 'last_name',
        sorter: (a, b) => a.last_name > b.last_name ? 1 : -1,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'last_name' },
    },
    {
        title: 'EMAIL',
        dataIndex: 'email',
        sorter: (a, b) => a.email > b.email ? 1 : -1,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'email' },
    },
    {
        title: 'ROLE',
        dataIndex: 'role',
        sorter: (a, b) => a.role > b.role ? 1 : -1,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'role' },
    },
    {
        title: 'ACTION',
        dataIndex: 'id',
        scopedSlots: { customRender: 'action' },
    },
];

export default {
    data() {
        return {
            columns,
            admins: [],
            pageSize: 10,
            currentPage: 1,
            totalCount: 0,
            query: '',
            selectedRowKeys: [],
            isNotLoading: false,
            debouncedSearch: () => { },
        }
    },
    methods: {
        getRoleColor(role) {
            switch (role) {
                case 'admin':
                    return 'blue'; // Color for admin role
                case 'super_admin':
                    return 'red'; // Color for super_admin role
                default:
                    return 'default'; // Default color
            }
        },
        getRoleLabel(role) {
            switch (role) {
                case 'admin':
                    return 'Admin'; // Label for admin role
                case 'super_admin':
                    return 'Super Admin'; // Label for super_admin role
                default:
                    return 'Unknown'; // Default label
            }
        },
        async handlePageChange(page) {
            this.currentPage = page;
            const data = await this.getAdmins({
                "limit": this.pageSize,
                "skip": (this.currentPage - 1) * this.pageSize,
            })
            if (data.code == "SUCCESS") {
                this.admins = data.data.admins
                this.totalCount = data.data.count
            } else {
                this.totalCount = 0
                this.admins = []
            }
            this.isNotLoading = false;
        },

        async getAdmins(filter) {
            this.isNotLoading = true;
            return adminAPI.getAdmins(filter);
        },

        async onSearchChange() {
            if (this.query.length >= 3) {
                const data = await this.getAdmins({
                    "limit": this.pageSize,
                    "skip": 0,
                    "query": this.query,
                })
                if (data.code == "SUCCESS") {
                    this.admins = data.data.admins
                    this.totalCount = data.data.count
                } else {
                    this.totalCount = 0
                    this.admins = []
                }
                this.isNotLoading = false;
            } else {
                const originalData = await this.getAdmins({
                    "limit": this.pageSize,
                    "skip": 0,
                });
                if (originalData.code == "SUCCESS") {
                    this.admins = originalData.data.admins
                    this.totalCount = originalData.data.count
                } else {
                    this.totalCount = 0
                    this.admins = []
                }
                this.isNotLoading = false;
            }
        },

        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },

    },
    async mounted() {
        this.debouncedSearch = debounce(this.onSearchChange, 300);
        const data = await this.getAdmins({
            "limit": this.pageSize,
            "skip": (this.currentPage - 1) * this.pageSize,
        })
        if (data.code == "SUCCESS") {
            this.admins = data.data.admins
            this.totalCount = data.data.count
        } else {
            this.totalCount = 0
            this.admins = []
        }
        this.isNotLoading = false;
    },
}

</script>

<style lang="scss" scoped>
.product-img {
    border-radius: 12px;
}

.alert {
    font-weight: bold;
    background-color: #FEEBC8;
    border-left: 4px solid #F59E0B;
    color: #F59E0B;
    padding: 0.5rem;
    margin: 10px;
}

.alert p.font-bold {
    font-weight: bold;
}
</style>
